const fakeData = {
	code: 0,
	data: {
		activateCode: 12,
		activateDate: 12,
		batchId: 0,
		createDate: 12,
		creator: 0,
		endDate: 12,
		id: 0,
		packageId: 0,
		source: 12,
		startDate: 12,
		status: 123123,
		updateDate: 12,
		updater: 0,
		userId: 0,
		userRightList: [
			{
				activatePeriod: 0,
				availableCount: 0,
				cardId: 0,
				createDate: 12,
				creator: 0,
				endDate: 12,
				id: 0,
				rightCode: 12,
				rightFlowList: [
					{
						createDate: 12,
						creator: 0,
						id: 0,
						rightId: 0,
						updateDate: 12,
						updater: 0,
						userId: 0,
						userRightId: 0
					},
					{
						createDate: 12,
						creator: 0,
						id: 0,
						rightId: 0,
						updateDate: 12,
						updater: 0,
						userId: 0,
						userRightId: 0
					},
				],
				rightId: 0,
				rightName: 12,
				source: 12,
				startDate: 12,
				status: 0,
				updateDate: 12,
				updater: 0,
				userId: 0,
				waitPeriod: 0
			},
			{
				activatePeriod: 0,
				availableCount: 0,
				cardId: 0,
				createDate: 12,
				creator: 0,
				endDate: 12,
				id: 0,
				rightCode: 12,
				rightFlowList: [
					{
						createDate: 12,
						creator: 0,
						id: 0,
						rightId: 0,
						updateDate: 12,
						updater: 0,
						userId: 0,
						userRightId: 0
					},
					{
						createDate: 12,
						creator: 0,
						id: 0,
						rightId: 0,
						updateDate: 12,
						updater: 0,
						userId: 0,
						userRightId: 0
					},
				],
				rightId: 0,
				rightName: 12,
				source: 12,
				startDate: 12,
				status: 0,
				updateDate: 12,
				updater: 0,
				userId: 0,
				waitPeriod: 0
			},
		]
	},
	msg: 12
}
export default fakeData;
